<template>
  <div class="products">
    <v-container fluid>
      <div class="welcome">
        <h1>Bag Stock</h1>
        <v-btn
          color="primary"
          depressed
          @click.native.prevent="$router.push('/products/create')"
        >
          <v-icon class="me-2">mdi-plus</v-icon>
          Add Bag
        </v-btn>
      </div>

      <products-table></products-table>
    </v-container>
  </div>
</template>

<script>
import ProductsTable from "../../../components/products/ProductsTable.vue";
export default {
  components: { ProductsTable },
};
</script>

<style scoped lang="scss">
.loading {
  text-align: center;
  padding-top: 20px;
}

.products {
  padding: 15px;

  .welcome {
    margin-bottom: 25px;
    display: flex;
    align-items: center;

    h1 {
      flex-grow: 1;
    }
  }
}
</style>