var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"products-table"},[_c('div',{staticClass:"toolbar"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"toolbar-option"},[_c('v-select',{staticClass:"form-control",attrs:{"label":"Status","items":_vm.statusDropdownOptionsForToolbar},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1),_c('div',{staticClass:"toolbar-option"},[_c('v-select',{staticClass:"form-control",attrs:{"label":"Owned By","items":_vm.ownedByDropdownOptions},model:{value:(_vm.investorAssignedFilter),callback:function ($$v) {_vm.investorAssignedFilter=$$v},expression:"investorAssignedFilter"}})],1)]),_c('div',{staticClass:"right"},[_c('v-text-field',{attrs:{"label":"Search","type":"text"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.products,"options":_vm.options,"server-items-length":_vm.totalProducts,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"mr-md rounded mr-8",attrs:{"height":"40","width":"40","gradient":"to bottom, rgba(0,0,0,.05), rgba(0,0,0,.05)","src":_vm.getProductPrimaryImage(item)}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getHumanReadableStatus(item.status))+" ")]}},{key:"item.account_id",fn:function(ref){
var item = ref.item;
return [(item.account_id)?_c('v-icon',{staticClass:"success--text"},[_vm._v("mdi-check")]):_c('v-icon',{staticClass:"danger--text"},[_vm._v("mdi-window-close")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","depressed":""},nativeOn:{"click":function($event){$event.preventDefault();return _vm.$router.push('/products/' + item.id)}}},[_c('v-icon',{staticClass:"me-2",attrs:{"small":""}},[_vm._v("mdi-eye")]),_vm._v(" View")],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }