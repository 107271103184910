<template>
  <div class="products-table">
    <!-- Toolbar -->
    <div class="toolbar">
      <div class="left">
        <div class="toolbar-option">
          <v-select label="Status" v-model="statusFilter" :items="statusDropdownOptionsForToolbar" class="form-control">
          </v-select>
        </div>
        <div class="toolbar-option">
          <!-- <v-select
            label="Investor Assigned"
            v-model="investorAssignedFilter"
            :items="investorAssignedDropdownOptions"
            class="form-control"
          >
          </v-select> -->
          <v-select label="Owned By" v-model="investorAssignedFilter" :items="ownedByDropdownOptions"
            class="form-control">
          </v-select>
        </div>
      </div>
      <div class="right">
        <v-text-field v-model="searchQuery" label="Search" type="text"></v-text-field>
      </div>
    </div>
    <!-- / Toolbar -->

    <v-data-table :headers="headers" :items="products" :options.sync="options" :server-items-length="totalProducts"
      :loading="loading" class="elevation-1">
      <template v-slot:item.image="{ item }">
        <v-img class="mr-md rounded mr-8" height="40" width="40" gradient="to bottom, rgba(0,0,0,.05), rgba(0,0,0,.05)"
          :src="getProductPrimaryImage(item)" />
      </template>
      <template v-slot:item.status="{ item }">
        {{ getHumanReadableStatus(item.status) }}
      </template>
      <template v-slot:item.account_id="{ item }">
        <v-icon class="success--text" v-if="item.account_id">mdi-check</v-icon>
        <v-icon class="danger--text" v-else>mdi-window-close</v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn small depressed @click.native.prevent="$router.push('/products/' + item.id)">
          <v-icon small class="me-2">mdi-eye</v-icon>
          View</v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import products from "../../api/products";
import Products from "../../mixins/Products";
export default {
  mixins: [Products],
  data() {
    return {
      statusDropdownOptionsForToolbar: [
        {
          value: null,
          text: "Show All",
        },
        {
          value: 0,
          text: "Available for consignor to purchase",
        },
        {
          value: 1,
          text: "Assigned stock to consignor",
        },
        {
          value: 2,
          text: "Paid for by consignor",
        },
        {
          value: 3,
          text: "Sold to end client",
        },
      ],

      // investorAssignedDropdownOptions: [
      //   {
      //     value: null,
      //     text: "Show All",
      //   },
      //   {
      //     value: true,
      //     text: "Yes",
      //   },
      //   {
      //     value: false,
      //     text: "No",
      //   },
      // ],

      ownedByDropdownOptions: [
        {
          value: null,
          text: "Show All",
        },
        {
          value: true,
          text: "A Consignor",
        },
        {
          value: false,
          text: "MAIA",
        },
      ],

      searchQuery: "",
      statusFilter: null,
      investorAssignedFilter: null,

      totalProducts: 0,
      products: [],
      loading: true,
      options: {},
      headers: [
        {
          value: "image",
          width: "40px",
          sortable: false
        },
        {
          text: "Bag Description",
          align: "start",
          value: "name",
        },
        // { text: "Description", value: "description" },
        { text: "Status", value: "status" },
        { text: "Stock Purchase Number", value: "stock_number" },
        { text: "Sales Invoice Number", value: "sales_number" },
        { text: "Consignor Assigned?", value: "account_id" },
        { text: "Consignor", value: "account.user.name" },
        { text: "Actions", value: "actions", sortable: false, width: "100px" },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.fetch();
      },
      deep: true,
    },

    statusFilter() {
      this.fetch();
    },

    investorAssignedFilter() {
      this.fetch();
    },

    searchQuery() {
      this.fetch();
    },
  },
  methods: {
    getFormParams() {
      let params = {};

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      params.resultsPerPage = itemsPerPage;
      params.page = page;
      if (sortBy.length) {
        params.sortField = _.first(sortBy);
      }
      if (sortDesc.length) {
        let isDescending = _.first(sortDesc);
        params.sortOrder = isDescending ? "descend" : "ascend";
      }

      if (this.statusFilter !== null) {
        params.status = this.statusFilter;
      }

      if (this.investorAssignedFilter !== null) {
        params.investor_assigned = this.investorAssignedFilter;
      }

      if (this.searchQuery && this.searchQuery.trim().length) {
        params.q = this.searchQuery;
      }

      return params;
    },

    fetch() {
      this.loading = true;
      products.search(this.getFormParams()).then((r) => {
        this.products = r.data.data;
        this.totalProducts = r.data.total;
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss">
.products-table {
  .toolbar {
    margin-bottom: 10px;
    display: flex;

    flex-direction: column;

    @media screen and (min-width: 960px) {
      flex-direction: row;

      .left {
        display: flex;
      }

      .toolbar-option {
        max-width: 300px;
        margin-right: 30px;
      }

      .right {
        .v-input {
          width: 300px;
        }
      }
    }


    .left {
      flex-grow: 1;
    }

    .right {
      flex-shrink: 1;
    }

    .toolbar-option {
      width: 100%;
    }
  }

  tbody {
    tr:hover {
      background-color: transparent !important;
    }
  }
}
</style>
